.topcorner{
    position:absolute;
    top:0;
    right:0;
   }
.topcornerFilter{
    position:absolute;
    top:50;
    right:0;
}   
.header-menu {
    flex: 1;
    background: #333333;
    padding: 1% 20%;

    display: flex;
    justify-content: space-between;
}

.header-menu img {
    height: 50px;
}

.header-menu ul {
    list-style: none;
    display: flex;
    height: 50%;
}

.header-menu ul li {
    margin-left: 16px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;
}

.header-menu ul li a {
   color: #fff;
}

.sub-menu {
    flex: 1;
    background: #fff;
    padding: 1% 20%;

    display: flex;
    justify-content: space-between;
}

.sub-menu img {
    height: 100px;
}

.sub-menu ul {
    /* list-style: none; */
    display: flex;
    height: 50%;
}

.sub-menu ul li {
    margin-left: 16px;
    display: flex;
    align-items: center;
}

.sub-menu ul li a {
    text-decoration: none;
    font-weight: 400;
    font-size: 1.13rem;
    font-family: Roboto,sans-serif;

}

.sub-menu ul li a.active {
    color: #f68b1f;
    /* Coral */
}

.campo-busca {
    padding-left: 50px;
    /* background-color: black; */
}